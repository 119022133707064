import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useAppDispatch } from "@/hooks/useAppDispatch";
import { useAppSelector } from "@/hooks/useAppSelector";
import { logout } from "@/reduxtoolkit/slices/userSlice";
import styles from "@/styles/layout/header.module.scss";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { Container } from "@mui/system";
import Image from "next/image";
import assest from "@/json/assest";
import CustomButtonOutline from "@/ui/Buttons/CustomButtonOutline";
import { Button } from "@mui/material";
import UserLogin from "dashboardcomponents/UserLogin/UserLogin";

const CustomButton = dynamic(() => import("@/ui/Buttons/CustomButton"));

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

const HeaderWrap = styled(Box)`
  &.main_head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    transition: all 0.4s;
    .ham_btn_sec{
      padding: 0;
      margin: 0;
      width: auto;
      height: auto;
      border: 0;
      border-radius: 0;
      margin-top: -10px;
      margin-right: 12px;
      @media(max-width: 479px){
        margin-right: 8px;
      }
    }
    &.fixed {
      background: linear-gradient(
        121.15deg,
        #1c1854 6.04%,
        #081440 95.29%
      ) !important;
      .headerContainer {
        padding: 15px 0;
        .headerLogo {
          width: 240px;
          margin-top: 0;
          @media (max-width: 1400px) {
            width: 180px;
          }
          
        }
      }
    }
  }

  .headerContainer {
    background-color: transparent !important;
    padding: 34px 0;
    transition: all 0.4s;
    @media (max-width:1400px) {
      padding: 26px 0;
        
    }
    @media(max-width: 1199px){
      > *{
          > *{
            padding-left: 0;
            padding-right: 0;
            min-height: inherit;
          }
      }
    }
    @media(max-width: 899px){
      padding: 20px 0;
    }
  }
  .hdr_rgt {
    display: flex;
    align-items: center;
    @media(max-width: 899px){
      margin-left: auto;
    }
    .srch {
      padding: 0;
      min-width: auto;
      min-height: auto;
      margin: 0 32px;
      &:hover {
        background-color: transparent;
      }
    }
    .btn1 {
      margin-left: 20px;
      @media(max-width: 1199px){
        margin-left: 15px;
      }
      @media(max-width: 479px){
        margin-left: 5px;
      }
    }
    .MuiButtonBase-root{
      @media(max-width: 1199px){
        min-width: 114px;
        padding: 11px 16px;
      }
      @media(max-width: 899px){
        min-width: 100px;
        padding: 10px 12px;
        span{
          font-size: 13px;
        }
      }
      @media(max-width: 479px){
        min-width: 85px;
        padding: 8px 6px;
        span{
          font-size: 12px;
        }
      }
      @media(max-width: 359px){
        min-width: 75px;
        padding: 8px 6px;
        span{
          font-size: 11px;
        }
      }
    }
  }
  .headerLogo {
    width: 280px;
    display: inline-block;
    margin-top: -25px;
    transition: all 0.4s;
    @media (max-width: 1400px) {
      width: 200px;
    }
    @media(max-width: 1199px){
      width: 170px;
      margin-top: 0;
    }
    @media (max-width: 479px){
      margin-right: 8px;
    }
  }
  .navbar {
    margin-left: auto;
    margin-right: 20px;
    @media(max-width: 1199px){
      margin-right: 14px;
    }
    a {
      display: inline-block;
      margin-right: 54px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      @media (max-width: 1400px) {
        margin-right: 25px;
        
      }
      @media(max-width: 1199px){
        margin-right: 10px;
      }

      &:hover {
        color: var(--white);
      }
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
      &.active {
        color: var(--white);
      }
    }
  }
  
`;

export default function Header(props: Props) {
  // const { isLoggedIn } = useAppSelector(s => s.userSlice)

  const navItems = [
    {
      name: "Home",
      route: "/",
    },
    {
      name: "About Us",
      route: "/about",
    },
    {
      name: "Help / FAQ",
      route: "/faq",
    },
    {
      name: "Contact Us",
      route: "/contact",
    },
  ];

  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { userData, isLoggedIn } = useAppSelector((state) => state.userSlice);
  const dispatch = useAppDispatch();
  const router = useRouter();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    router.push("/signin");
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      {/* <Typography variant="h6" sx={{ my: 2 }}>
          <Link href="/" className="headerLogo">
              <Image
                src={assest.logo_image}
                width={278}
                height={35}
                alt="Logo"
              />
          </Link>
      </Typography>
      <Divider /> */}
      <Box className="closes_div_sec" onClick={handleDrawerToggle}>
        &#x2716;
      </Box>
      <List className="list_items_header_mbl">
        {navItems.map((item) => (
          <Link href={item?.route} key={item.name} className={router.pathname === item.route ? "active" : ""}>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  const [scroll, setScroll] = React.useState(false);

  const detectScroll = React.useCallback(() => {
    setScroll(window.scrollY > 100);
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", detectScroll);
    return () => {
      window.removeEventListener("scroll", detectScroll);
    };
  }, []);

  return (
    <HeaderWrap
      sx={{ display: "flex" }}
      className={`main_head ${scroll ? "fixed" : ""}`}
    >
      <AppBar
        component="nav"
        position="static"
        elevation={0}
        className="headerContainer"
      >
        <Container fixed className="cus_container">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className="ham_btn_sec"
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Link href="/" className="headerLogo">
              <Image
                src={assest.logo_image}
                width={278}
                height={35}
                alt="Logo"
              />
            </Link>

            <Box
              sx={{ display: { xs: "none", md: "block" } }}
              className="navbar"
            >
              {navItems.map((item) => (
                <Link
                  href={item?.route}
                  key={item.name}
                  className={router.pathname === item.route ? "active" : ""}
                >
                  {/* <CustomButton type="button" variant="text"> */}
                  {item?.name}
                  {/* </CustomButton> */}
                </Link>
              ))}
            </Box>


            {/* {isLoggedIn ? (
              <Box
                sx={{ display: { xs: "none", md: "block" } }}
                className="navbar"
              >
                <CustomButton
                  onClick={handleLogout}
                  type="button"
                  variant="text"
                >
                  <span>Logout</span>
                </CustomButton>

                <CustomButton type="button" variant="text">
                  <span>{userData?.email}</span>
                </CustomButton>
              </Box>
            ) : (
              <Box
                sx={{ display: { xs: "none", md: "block" } }}
                className="navbar"
              >
            //     {navItems.map((item) => (
            //       <Link
            //         href={item?.route}
            //         key={item.name}
            //         className={router.pathname === item.route ? "active" : ""}
            //       >
            //         {/* <CustomButton type="button" variant="text"> */}
            {/* //         {item?.name} 
            //         {/* </CustomButton> */}
            {/* //       </Link>
            //     ))}
            //   </Box> */}
            {/* // )} */}
            <Box className="hdr_rgt">
              {/* <Button className="srch">
                {" "}
                <Image
                  src={assest.search_icon}
                  alt=""
                  width={24}
                  height={24}
                />{" "}
              </Button> */}
              {
                isLoggedIn && userData ? (

                  <UserLogin />

                ) : (<>
                  <CustomButton type="button" onClick={() => router.push("/signin")} >
                    <Typography variant="caption">SIGN IN</Typography>
                  </CustomButton>
                  {/* <Box className="btn1">
                    <CustomButtonOutline type="button" onClick={() => router.push("/signup")}>
                      <Typography variant="caption">SIGN UP</Typography>
                    </CustomButtonOutline>
                  </Box> */}
                </>)
              }

            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Toolbar />
    </HeaderWrap>
  );
}
