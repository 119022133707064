import { useAppSelector } from "@/hooks/useAppSelector";
import assest from "@/json/assest";
import styled from "@emotion/styled";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import { logout } from "@/reduxtoolkit/slices/userSlice";
import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Image from "next/image";
import React, { useEffect } from "react";

import { useAppDispatch } from "@/hooks/useAppDispatch";
import useNotiStack from "@/hooks/useNotistack";
import { useRouter } from "next/router";
import { mediaUrl } from "@/api/endpoints";

const UserWrap = styled(Box)`
  .arrw2 {
    width: 10px;
    margin-left: 10px;
    @media(max-width: 479px){
      margin-left: 3px;
    }
  }
  .acnt-btn {
    background-color: transparent;
    margin: 0;
    .MuiAvatar-root{
      @media(max-width: 479px){
        width: 30px;
        height: 30px;
        margin: 0 3px 0 0;
      }
    }
    &:hover {
      background: transparent;
    }
  }
  .user-name {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: var(--white);
    margin-left: 12px;
    @media(max-width: 767px){
      display: none;
    }
    @media(max-width: 479px){
      font-size: 13px;
      margin-left: 8px;
    }
    .welcome_msg{
      font-size: 100%;
      @media(max-width: 767px){
        display: none;
      }
    }
  }
`;

export default function UserLogin() {
  const { userData } = useAppSelector((state) => state.userSlice);
  // console.log('userData=============>',userData)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  //const { toastSuccess } = useNotiStack();
  const router = useRouter();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    router.push("/signin");
  };

  useEffect(() => {
    console.log(router.asPath, 'currentPath')
  }, [router.asPath])

  //   console.log(userData?.profile_pic, "profile_pic");
  return (
    <UserWrap>
      <Box
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
      >
        <Button
          className="acnt-btn"
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 44, height: 44 }}>
            {
              userData?.data?.profile_pic ? (
                <Image src={mediaUrl(`user/profile_pic/${userData?.data?.profile_pic}`)} alt="" width={44} height={44} />

              ) : `${userData?.data?.first_name?.charAt(0)}`
            }
          </Avatar>

          <Typography variant="body1" className="user-name">
            <Typography variant="caption" className="welcome_msg" /> {userData?.data?.first_name}!
          </Typography>
          <Image
            src={assest.down_arrw}
            width={5}
            height={10}
            alt=""
            className="arrw2"
          />
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => {
          if (router.asPath === '/dashboard/editprofile/')
            window.location.href = "/dashboard/editprofile/?type=edit"
          else
            router.push({ pathname: "/dashboard/editprofile/", query: { type: 'edit' } })
        }}>
          <Avatar sx={{ width: 44, height: 44 }}>
            {
              userData?.data?.profile_pic ? (
                <Image src={mediaUrl(`user/profile_pic/${userData?.data?.profile_pic}`)} alt="" width={44} height={44} />

              ) : `${userData?.data?.first_name?.charAt(0)}`
            }
          </Avatar> My account
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </UserWrap>
  );
}
